<template>
  <div class="app-container">
    <div class="flex">
      <div class="top" style="margin: 10px 10px 10px 0">
        <el-button icon=" el-icon-upload2" type="primary" @click="exportExcel"
          >导出</el-button
        >
        <el-button
          type="success"
          icon="el-icon-edit"
          @click="showEditForm(selectionData[0])"
          :disabled="selectionData.length !== 1"
          >编辑</el-button
        >
        <el-button
          type="warning"
          icon="el-icon-download"
          @click="showDowQrcode"
          :disabled="selectionData.length < 1"
          >下载二维码</el-button
        >
      </div>
      <div class="search flex-2" style="margin: 10px 0">
        <el-input
          clearable
          placeholder="按商户姓名查询"
          v-model="searchQuery.name"
          class="searchInput"
        >
          <template slot="prepend">商户姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="按开户姓名查询"
          v-model="searchQuery.accountName"
          class="searchInput"
        >
          <template slot="prepend">开户姓名</template>
        </el-input>
        <el-input
          clearable
          placeholder="按摊位编号查询"
          v-model="searchQuery.stallNo"
          class="searchInput"
        >
          <template slot="prepend">摊位编号</template>
        </el-input>
        <div class="searchInput el-input-group">
          <div class="el-input-group__prepend">支付参数</div>
          <el-select v-model="searchQuery.isWithPayParam" placeholder="请选择">
            <el-option label="有" value="1"></el-option>
            <el-option label="无" value="2"></el-option>
          </el-select>
        </div>
      </div>
    </div>
    <!-- 加载数据 -->
    <el-table
      :data="listData"
      border
      highlight-current-row
      style="width: 100%"
      @selection-change="handleSelectionChange($event)"
    >
      <el-table-column type="selection"> </el-table-column>
      <el-table-column
        prop="name"
        label="商户姓名"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="stallNo"
        label="摊位编号"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="accountName"
        label="开户姓名"
        align="center"
      ></el-table-column>
      <el-table-column label="协议编码" prop="storeCode" align="center">
      </el-table-column>
      <el-table-column label="商户编码" prop="merId" align="center">
      </el-table-column>
      <el-table-column label="操作" width="180">
        <template slot-scope="scope">
          <el-link type="primary" @click="previewQrcode(scope.row)"
            >查看二维码</el-link
          >
        </template>
      </el-table-column>
    </el-table>
    <div style="margin-top: 15px">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="searchQuery.pagenum"
        :page-sizes="[50, 100, 150, 200]"
        :page-size="searchQuery.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="searchQuery.total"
      />
    </div>
    <!-- 编辑的表单 -->
    <el-dialog
      title="编辑"
      :visible="dialogVisible"
      width="30%"
      :before-close="
        () => {
          dialogVisible = false;
        }
      "
      :close-on-click-modal="false"
    >
      <div>
        <el-form
          ref="editForm"
          :rules="editRules"
          :model="form"
          label-width="80px"
        >
          <el-form-item label="商户姓名" label-width="80px" prop="name">
            <el-input v-model="form.name" disabled></el-input>
          </el-form-item>
          <el-form-item label="摊位编号" label-width="80px" prop="stallNo">
            <el-input v-model="form.stallNo" disabled></el-input>
          </el-form-item>
          <el-form-item label="开户姓名" label-width="80px" prop="accountName">
            <el-input v-model="form.accountName" clearable></el-input>
          </el-form-item>
          <el-form-item label="商户编码" label-width="80px" prop="merId">
            <el-input v-model="form.merId" clearable></el-input>
          </el-form-item>
          <el-form-item label="协议编码" label-width="80px" prop="storeCode">
            <el-input v-model="form.storeCode" clearable></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            () => {
              dialogVisible = false;
            }
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="editData('editForm')"
          >确 定</el-button
        >
      </span>
    </el-dialog>
    <!-- 编辑的表单 -->
    <el-dialog
      title="下载二维码"
      :visible="dowDialogVisible"
      width="30%"
      :before-close="
        () => {
          dowDialogVisible = false;
        }
      "
      :close-on-click-modal="false"
    >
      <div>
        <el-form ref="form" label-width="80px">
          <el-form-item label="二维宽度">
            <el-input
              v-model.number="qrForm.width"
              placeholder="输入二维码宽度"
            ></el-input>
          </el-form-item>
        </el-form>
        <!-- <div class="flex flex-wrap justify-between">
          <template v-for="(item, index) in qrCodeFile">
            <img :src="item.url" :key="index" alt="" />
            <canvas :key="item.id" :id="`myCanvas${index}`"></canvas>
          </template>
        </div> -->
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="dowDialogVisible = false">取 消</el-button>
        <!-- <el-button @click="previewQrcode">预 览</el-button> -->
        <el-button type="primary" @click="submitDowQrcode">下 载</el-button>
      </div>
    </el-dialog>
    <div class="qr-wrap">
      <vue-qr
        v-for="(item, index) in qrCodeData"
        :key="item.id"
        :ref="`qrCode${index}`"
        :id_name="'qcode_' + index"
        :text="item.url"
        :callback="getQcodeUrl"
        :bindElement="false"
        :margin="0"
        colorDark="#333"
        colorLight="#fff"
        :logoSrc="item.logoSrc"
        :size="item.width"
        :logoScale="0.2"
        :qid="item.qid"
      ></vue-qr>
    </div>
    <el-dialog
      :visible.sync="qrCodeVisible"
      center
      :close-on-click-modal="false"
      destroy-on-close
    >
      <div class="qr-code-wrap">
        <vue-qr
          ref="qrCode"
          :text="downloadData.url"
          :margin="0"
          colorDark="#333"
          colorLight="#fff"
          :logoSrc="downloadData.imagePath"
          :size="200"
          :logoScale="0.2"
        ></vue-qr>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="qrCodeVisible = false">取 消</el-button>

        <el-button type="primary" @click="dowQrcode">下 载</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import api from "@/api/common";
import vueQr from "vue-qr";
import JSZip from "jszip";
import FileSaver from "file-saver";
export default {
  components: { vueQr },
  data() {
    return {
      qrCodeVisible: false,
      editRules: {
        accountName: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (value === "") {
                callback(new Error("开户姓名不能为空"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        merId: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (value === "") {
                callback(new Error("商户编码不能为空"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("商户编码以数字组成"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
        storeCode: [
          {
            required: true,
            validator: (relu, value, callback) => {
              if (value === "") {
                callback(new Error("协议编码不能为空"));
              } else if (!/^\d+$/.test(value)) {
                callback(new Error("协议编码以数字组成"));
              } else {
                callback();
              }
            },
            trigger: ["blur", "change"],
          },
        ],
      },
      qrCodeData: [],
      listData: [],
      dialogVisible: false,
      dowDialogVisible: false,
      qrForm: {
        width: 200,
        url: "",
        imagePath: require("../../assets/logo.png"),
      },
      form: {
        name: "",
        stallNo: "",
        id: null,
        accountName: "",
        merId: "",
        storeCode: "",
      },
      paymentUrl: "",
      searchQuery: {
        accountName: "",
        currentPage: 1,
        pageSize: 50,
        total: null,
        stallNo: "",
        idCard: null,
        name: "",
        phone: null,
        isWithPayParam: null,
      },
      selections: [], //复选数据id
      selectionData: [], //复选数据
      qrCodeFile: [],
      zipName: process.env.VUE_APP_NAME,
      qrNum: 0,
      downloadData: {
        url: "",
        imagePath: require("../../assets/logo.png"),
      },
    };
  },
  created() {
    this.getDataList();
    this.getConfig();
    console.log("process.env.VUE_APP_HOST", this.zipName);
  },
  watch: {
    searchQuery: {
      handler() {
        this.getDataList();
      },
      deep: true, //开启深度监听
    },
    qrCodeFile: {
      handler(val) {
        console.log("val", val);

        if (this.selectionData.length == val.length) {
          this.downloadZip();
        }
      },
      deep: true, //开启深度监听
    },
  },
  methods: {
    // 显示下载二维码内容
    showDowQrcode() {
      if (this.selectionData.length > 0) {
        let arr = this.selectionData.filter((x) => x.accountName == "");
        if (arr.length > 0) {
          let str = arr
            .map((x) => {
              return x.name;
            })
            .join("、");
          console.log("strstrstrstrstrstr", str);
          this.$message({
            type: "error",
            message: `${str}商户信息不全`,
          });
        } else {
          this.dowDialogVisible = true;
        }
      }
    },
    // 查看二维码
    previewQrcode(data) {
      console.log("data", data);

      let url = this.paymentUrl + encodeURIComponent(data.id);
      this.downloadData.url = url;
      this.qrCodeVisible = true;
      // console.log("插卡看", data);
    },
    // 下载单张二维码
    dowQrcode() {
      const qrSrc = this.$refs.qrCode.$el.src;
      let a = document.createElement("a");
      // 下载图名字
      a.download = "二维码";
      //url
      a.href = qrSrc;
      //合成函数，执行下载
      a.dispatchEvent(new MouseEvent("click"));
    },
    // 提交下载二维码
    submitDowQrcode() {
      this.qrCodeData = this.selectionData.map((x, i) => {
        return {
          url: this.paymentUrl + encodeURIComponent(x.id),
          logoSrc: require("../../assets/logo.png"),
          width: this.qrForm.width,
          qid: x.accountName,
          name: x.accountName,
          id: x.id,
          index: i,
        };
      });
    },
    // 获取二维码地址
    getQcodeUrl(url, name) {
      let index = this.qrCodeData.filter((x) => x.name == name)[0].index;
      let qrItem = {
        url: url,
        name: name,
        width: this.qrForm.width,
        index,
      };
      this.addText(qrItem);
    },
    // 使用画布在二维码下面加上文字
    addText(item) {
      const that = this;
      let canvas = document.createElement("canvas");
      // let canvas = document.getElementById(`myCanvas${item.index}`);
      canvas.height = item.width + 60;
      canvas.width = item.width + 40;
      let ctx = canvas.getContext("2d");
      ctx.fillStyle = "#fff";
      ctx.fillRect(0, 0, canvas.width, canvas.height);
      // 新建图片然后给图片赋值
      var img = new Image();
      img.height = item.width;
      img.width = item.width;
      img.src = item.url;
      img.onload = function () {
        // 将图片画到canvas上面上去！
        ctx.drawImage(img, 20, 20);

        ctx.fillStyle = "#333"; // 文字填充颜色
        ctx.font = `${item.width / 9.09}px Georgia`;
        let text = `${that.zipName}-${item.name}`;
        // 为了居中先获取文字的宽度（如果有更好的方法请提供）
        let textWidth = ctx.measureText(text).width.toFixed(2);
        // 计算居中显示 画布宽度-文字宽度 / 2 为文字起始坐标
        let w = (canvas.width - textWidth).toFixed(2) / 2;
        ctx.fillText(text, w, item.width + 50);
        // 设置垂直对齐方式
        ctx.textBaseline = "middle";
        ctx.textAlign = "center";

        let obj = {
          url: canvas.toDataURL("image/png"),
          name: item.name,
        };
        console.log("obj", obj);

        that.qrCodeFile.push(obj);
      };

      // if (this.qrCodeData.length == this.qrCodeData.length) {
      //   this.downloadZip();
      //   //
      // }
    },
    downloadZip() {
      let that = this;
      const zip = new JSZip();
      console.log("this.qrCodeFile", this.qrCodeFile);

      this.qrCodeFile.forEach((item) => {
        const fileName = item.name + ".png";
        zip.file(fileName, item.url.substring(22), { base64: true }); //向zip中添加文件
      });
      zip.generateAsync({ type: "blob" }).then((content) => {
        // 生成二进制流
        FileSaver.saveAs(content, that.zipName + "--二维码.zip"); // 利用file-saver保存文件
        that.qrCodeFile = [];
      });
    },
    // 获取数据列表
    async getDataList() {
      await api
        .list({
          apiName: "/merchant/personnel/payConfig",
          currentPage: this.searchQuery.currentPage,
          idCard: this.searchQuery.idCard,
          name: this.searchQuery.name,
          pageSize: this.searchQuery.pageSize,
          phone: this.searchQuery.phone,
          stallNo: this.searchQuery.stallNo,
          accountName: this.searchQuery.accountName,
          isWithPayParam:
            this.searchQuery.isWithPayParam === ""
              ? 0
              : this.searchQuery.isWithPayParam,
        })
        .then((res) => {
          this.listData = res.data.data;
          this.searchQuery.total = parseInt(res.data.total);
        });
    },
    handleSizeChange(e) {
      this.searchQuery.pageSize = e;
      this.getDataList();
      console.log(`每页 ${e} 条`);
    },
    handleCurrentChange(e) {
      this.searchQuery.currentPage = e;
      this.getDataList();
      console.log(`当前页面`, e);
    },
    showEditForm(row) {
      console.log(row);
      this.form = row;
      this.dialogVisible = true;
      console.log("选中要修改的信息", this.form);
    },
    async editData(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          api
            .update({
              apiName: "/merchant/personnel/payConfig",
              id: this.form.id,
              accountName: this.form.accountName,
              merId: this.form.merId,
              storeCode: this.form.storeCode,
            })
            .then((res) => {
              if (res.code == 200) {
                this.$message({
                  message: "修改成功",
                  type: "success",
                });
                this.getDataList();
              } else {
                this.$message({
                  message: "修改失败",
                  type: "danger",
                });
                this.getDataList();
              }
            });
          this.dialogVisible = false;
        } else {
          this.$message({
            message: "信息填写不规范",
            type: "danger",
          });
          return false;
        }
      });
    },
    handleSelectionChange(select) {
      this.selectionData = select;
      let idArr = [];
      select.forEach((item) => {
        idArr.push(item.id);
      });
      this.selections = idArr;
      console.log("复选数据", this.selections);
    },
    async getConfig() {
      let res = await api.getConfig();
      console.log("res", res);
      if (res.code == 200) {
        this.paymentUrl = res.data.myPayUrl;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
    async exportExcel() {
      // const { searchQuery } = this;
      // console.log(searchQuery);
      await api.download({
        apiName: "/merchant/personnel/payConfig/exportExcel",
        accountName: this.searchQuery.accountName,
        idCard: this.searchQuery.idCard,
        isWithPayParam: this.searchQuery.isWithPayParam,
        name: this.searchQuery.name,
        phone: this.searchQuery.phone,
        stallNo: this.searchQuery.stallNo,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
}
.el-form-item label:after {
  content: "";
  display: inline-block;
  width: 100%;
}
.info-form label {
  font-weight: normal;
}
.el-form-item__label {
  text-align: justify;
  height: 50px;
}
.searchInput {
  // width: 400px;
}
.partInput {
  width: 365px;
  text-align: center;
}
.partBtn {
  width: 60px;
}
.partName {
  width: 100px;
  display: block;
  text-align: center;
}
.partBtn_key {
  width: 100px;
}
.el-select {
  /deep/ .el-input__inner {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}
.qr-code-wrap {
  display: flex;
  justify-content: center;
}
</style>
